import React, { styled, s, ThemeProvider, PropTypes, withData } from 'vendor'
import tinycolor from 'tinycolor2'

/**
 * see learn/web/gallery-clip.html
 */

class Project extends React.Component {
  static defaultProps = {
    backgroundColor: 'inherit',
    color: 'inherit',
    leftWidth: '50%',
    rightWidth: '50%',
  }

  render() {
    const { d, leftWidth, rightWidth, project: {image, title, company, year, stack, description} } = this.props
    return (
      <Root>
        <Clip>
          <Column width={leftWidth}>
            <LeftContainer enableScreenshot={d.enableScreenshot}>
              <div>
                <Img src={image} />
              </div>
            </LeftContainer>
          </Column>
          <Column width={rightWidth}>
            <RightContainer>
              <Title>{title}</Title>
              <Company>{company}</Company>
              <Year>{year}</Year>
              <Stack>{stack}</Stack>
              <Description dangerouslySetInnerHTML={{__html: description}} />
            </RightContainer>
          </Column>
        </Clip>
      </Root>
    )
  }
}

const Root = styled.section`
  background-color: ${p => p.theme.background};
  color: ${p => p.theme.text};
  ${s.tablet} {
    position: relative;
    height: 100vh;
  }
`

const Clip = styled.div`
  ${s.mobileOnly} {
    padding: 56px 16px 37px 16px;
  }
  ${s.tablet} {
    position: absolute;
    width: 100%;
    height: 100%;
    clip: rect(0, auto, auto, 0);
    display: flex;
    justify-content: center;
  }
`

const Column = styled.div`
  ${s.tablet} {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
    width: ${p => p.width};
  }
`

const LeftContainer = styled.div`
  ${s.tablet} {
    ${p => p.enableScreenshot ? `
    width: 100%;
    ` : `
    position: fixed;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: inherit;
    `}
    left: inherit;
    display: flex;
    justify-content: center;
  }
`

const Img = styled.img`
  ${s.tabletOnly} {
    width: 320px;
  }
`

const RightContainer = styled.div`
  ${s.tablet} {
    margin: 0 10%;
  }
`

const Title = styled.div`
  font-weight: bold;
  margin: 4px 0;
  font-size: 1.2rem;
`

const Company = styled.div`
  margin: 4px 0;
  font-size: 0.8rem;
  color: ${p => p.theme.textLight};
`
const Year = styled.div`
  margin: 4px 0;
  font-size: 0.8rem;
  color: ${p => p.theme.textLight};
`

const Stack = styled.div`
  margin: 4px 0;
  font-size: 0.8rem;
  color: ${p => p.theme.textLight};
`

const Description = styled.div`
  /* font-size: 1.2rem; */
  margin: 1rem 0;

  & a, & a:visited {
    color: ${p => p.theme.text}
  }
`

export default withData(Project)