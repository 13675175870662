export default {
  blue: {
    background: '#3B5999',
    text: '#D2D6E4',
    // text: '#FFFFFF',
  },

  gray: {
    background: '#E9EAED',
    text: '#707071',
    textLight: '#89898a',
    // text: '#3B5999',
  },

  yellow: {
    background: '#fed539',
    text: '#424242',
    textLight: '#5b5b5b',
  },

  black: {
    background: '#383838',
    text: '#fafafa',
    textLight: '#fffff',
  },

  tablet: '@media (min-width: 768px)',
  desktop: '@media (min-width: 1024px)',

  mobileOnly: '@media (max-width: 768px)',
  tabletOnly: '@media (min-width: 768px) and (max-width: 1024px)',
}
