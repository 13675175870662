import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  body {
    font-family: Helvetica, Arial, sans-serif;
  }

  img {
    max-width: 100%;
  }

  html, body {
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }

  a {
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  html::-webkit-scrollbar {
    display: none;
  }
`
