import React, { styled, s, keyframes } from 'vendor'
import IconArrowDown from 'assets/arrow-down.svg'

class ArrowDown extends React.Component {
  render() {
    return (
      <Root>
        <IconArrowDown viewBox='0 -5.5 35 30' />
      </Root>
    )
  }
}

const arrow = keyframes`
  0% {
    transform: translate3d(0px, 0px, 0px);
  }
  30% {
    transform: translate3d(0px, 7px, 0px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
`

const Root = styled.div`
  display: flex;
  justify-content: center;
  animation: ${arrow} 3s ease 0s infinite;
  animation-name: ${arrow};
  height: 39px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  & svg {
    width: 35px;
    height: 30px;
    stroke: ${p => p.theme.text};
    stroke-width: 2px;
    fill: none;
  }
`
export default ArrowDown
