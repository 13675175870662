import MarkdownIt from 'markdown-it'
export { default } from 'react'
export { default as styled, keyframes, ThemeProvider } from 'styled-components'
export { default as s } from './theme'
export { default as Link } from 'gatsby-link'
export { default as Helmet } from 'react-helmet'
export { default as PropTypes } from 'prop-types'
export { default as withData } from './components/withData'

global.pd = console.log.bind(console)

const markdownit = new MarkdownIt({
  breaks: true,
})

function md(doc) {
  return markdownit.render(doc)
}

export { md }