import React, { Helmet, s, PropTypes } from 'vendor'
import { ThemeProvider } from 'styled-components'
import { languages, defaultLanguage } from 'data/languages'
import data from 'data'
import GlobalStyle from './index.css.js'

class Layout extends React.Component {
  static childContextTypes = {
    lang: PropTypes.string,
    d: PropTypes.object
  }

  getChildContext() {
    const lang = this.getLang()
    return {
      lang,
      d: data[lang],
    }
  }

  render() {
    const d = data[this.getLang()]
    return (
      <ThemeProvider theme={s.blue}>
        <div>
          <GlobalStyle />
          <Helmet
            title={d.title}
            meta={[
              { name: 'description', content: 'Guten' },
              { name: 'keywords', content: 'gutenye' },
              { name: 'apple-mobile-web-app-capable', content: 'yes' },
              { name: 'apple-mobile-web-app-status-bar-style', content: '#3B5999' },
              { name: 'apple-mobile-web-app-title', content: 'Guten Ye' },
            ]}
            link={[
              { rel: 'icon', type: 'image/x-icon', href: '/favicon.png' },
              { rel: 'apple-touch-icon', href: 'icon.png' },
            ]}
          />
          {this.props.children}
        </div>
      </ThemeProvider>
    )
  }

  getLang() {
    return 'en'
  }
}

export default Layout