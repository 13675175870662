// used both in src/i18n.js and gatsby-node.js
module.exports = {
  // languages: ['en', 'cn'],
  // en only
  languages: ['en'],
  defaultLanguage: 'en',
  resources: {
    // en: { translation: require('./locales/en/translation.json') },
    // cn: { translation: require('./locales/cn/translation.json') },
  },
}