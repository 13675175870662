import React, { styled, Link, s, ThemeProvider, withData } from 'vendor'
import { Project } from 'components'
import Layout from 'layouts'
import { flatten, times } from 'lodash'
import Overview from './Overview'

// blue, gray, yellow, black
const themes = flatten(times(10, () => [ 'blue', 'gray' ])).map(v => s[v])

class HomePage extends React.Component {
  render() {
    const { d } = this.props
    return (
      <Root>
        <ThemeProvider theme={themes[0]}>
          <Overview />
        </ThemeProvider>
        {d.advantages && 
          <ThemeProvider theme={themes[1]}>
            <Page>
              <div className='inner' dangerouslySetInnerHTML={{__html: d.advantages}} />
            </Page>
          </ThemeProvider>
        }
        {d.projects.map((project, i) =>
          <ThemeProvider theme={themes[i+2]} key={i}> 
            <Project
              leftWidth='50%'
              rightWidth='48%'
              project={project}
            />
          </ThemeProvider>
        )}
      </Root>
    )
  }
}

const Root = styled.div`
`

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${p => p.theme.background};
  color: ${p => p.theme.text};
  flex-direction: column;
  padding: 0 1rem;
  font-size: 1.2rem;

  .inner {
    width: 60%;
  }

  & li {
    padding: 10px 0;
  }

`

const HomeWithData = withData(HomePage)

export default () => (
  <Layout>
    <HomeWithData />
  </Layout>
)