import React, { styled, s, withData } from 'vendor'
import { AvailableForFreelancing, ContactList } from 'components'
import ImageMe from './assets/me.png'
import ArrowDown from './ArrowDown'
import data from 'data'

class Overview extends React.Component {
  render() {
    const { d } = this.props
    return (
      <Root>
        <AvatarAndTextContainer>
          <AvatarAndText>
            <AvatarContainer>
              <Avatar src={ImageMe}></Avatar>
            </AvatarContainer>
            <TextContainer>
              <Title>Guten Ye</Title>
              <Subtitle>JavaScript, Linux and Go</Subtitle>
              <Text dangerouslySetInnerHTML={{__html: d.overview}} />
              <ContactList items={d.contacts} />
            </TextContainer>
          </AvatarAndText>
        </AvatarAndTextContainer>
        {d.availableForFreelancing && <AvailableForFreelancing />}
        <ArrowDown />
      </Root>
    )
  }
}

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${p => p.theme.background};
  color: ${p => p.theme.text};
  flex-direction: column;
  padding: 0 1rem;
`

const AvatarAndTextContainer = styled.div`
  flex: 4;
  display: flex;
  align-items: center;
`

const AvatarAndText = styled.div`
  display: flex;
  flex-direction: column;
  ${s.tablet} {
    flex-direction: row;
    max-width: 800px;
  }
`

const AvatarContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  ${s.mobileOnly} {
    margin-bottom: 2rem;
  }
  ${s.tablet} {
    margin-right: 2rem;
    padding-top: 2rem;
    justify-content: flex-end;
  }
`

const Avatar = styled.img`
  width: 8rem;
  height: 8rem;
`

const TextContainer = styled.div`
  flex: 4;
`

const Title = styled.div`
  color: ${p => p.text};
  font-size: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  text-align: center;
  margin-bottom: 0.7rem;
  ${s.tablet} {
    font-size: 5rem;
    text-align: left;
  }
`

const Subtitle = styled.div`
  color: ${p => p.theme.text};
  font-size: 1.2rem;
  line-height: 1.26;
  text-align: center;
  margin-bottom: 0.7rem;
  ${s.tablet} {
    font-size: 1.8rem;
    text-align: left;
    padding-left: 1rem;
  }
`

const Text = styled.div`
  color: ${p => p.theme.text};
  max-width: 500px;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 2rem;
  ${s.tablet} {
    font-size: 1.4rem;
    text-align: left;
  }
`

const Link = styled.a`
  color: ${p => p.theme.text};

  &:visited {
    color: ${p => p.theme.text};
  }
`

export default withData(Overview)