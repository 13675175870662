import React, { styled, withData } from 'vendor'

/*
 * <AvailableForWork />
 */

class AvailableForFreelancing extends React.Component {
  render() {
    const { d } = this.props
    return (
      <Root>
        <A href={`mailto:${d.email}`}>{d.availableForFreelancing}</A>
      </Root>
    )
  }
}

const Root = styled.div`
  flex: 1;
`

const A = styled.a`
  display: inline-block;
  font-size: 0.6rem;
  letter-spacing: 0.05rem;
  font-weight: 800;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  border-radius: 10px;
  background-color: ${p => p.theme.text};
  color: ${p => p.theme.background};
`

export default withData(AvailableForFreelancing)
