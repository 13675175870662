import React from 'react'
import styled from 'styled-components'

class Header extends React.Component {
  render() {
    const {children} = this.props
    return (
      <Root>
        {children}
      </Root>
    )
  }
}

const Root = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  & a {
    color: ${p => p.theme.text};
  }
`

export default Header
