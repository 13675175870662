import React, { s, md } from 'vendor'
import ImageItismyquoteDotCom from './assets/itismyquote.com.png'
import ImageGutenDotMe from './assets/guten.me.png'
import ImageStarofservice from './assets/starofservice.com.png'
import ImagePopup from './assets/popup.png'
import ImageJobboard from './assets/jobboard.png'
import IconEmail from './assets/email.svg'
import IconGithub from './assets/github.svg'
import IconLinkedin from './assets/linkedin.svg'

export default {
  // enableScreenshot: true,

  title: 'Guten - JavaScript and Go',

  email: 'ywzhaifei+gutenme@gmail.com',
  contacts: [
    { Icon: IconEmail, link: 'mailto:ywzhaifei+gutenme@gmail.com' },
    { Icon: IconGithub, link: 'https://github.com/gutenye' },
    { Icon: IconLinkedin, link: 'https://www.linkedin.com/in/gutenye' },
  ],

  availableForFreelancing: "目前接项目中...",
  overview: md(`
Guten是一位程序员, 黑客和开源爱好者.他同时也是一位高级JavaScript工程师, 拥有8年网页开发经验, SortableJS核心开发者. 他精通React, React Native, Angular, Polymer等框架.
  `),

  advantages: md(`
### 自由职业工作经验：
- 8年远程海外工作，网页开发经验；
- 6年React、Node.js全栈开发经验；
- 精通React、Mobx、GraphQL、Webpack、Node.js、PostgreSQL、Docker、Continus Integration；
- 熟练使用Google Cloud Platform，Amazon AWS，阿里云；
- 独立开发过多个完整项目（小项目如新生儿博客、特斯拉空调快速唤醒）；
### 学习能力：
纯英文环境下自学编程，通过查找资料，至今积累20多万行笔记，了解国内外前沿技术，深信技术是不断革新，Less is more.
  `),

  projects: [
    /*
    {
      title: 'ERP',
      description: 'React, Docker, Continus Integration',
      image: null,
    },
    */
    {
      title: 'React核心开发者',
      company: 'StarOfService, 法国',
      year: '04/2017 - Present',
      stack: 'React, Redux, CSS Modules',
     description: md(`
**项目简介**：StarOfService于2013年8月创立于法国巴黎，是一个O2O的人力服务平台（园艺、婚礼策划、电器维修、家装、健身等），用户可通过在StarOfService上搜索、发现并聘请当地专业人士，为自己提供上门服务。

**工作职责**：
1、管理团队
技术团队超20人，均来自世界各地（印度、法国、柬埔寨、芬兰、纽约等），每日需做团队的code review，视频会议且总结今天完成进度、遇到的bug难点、分配第二天任务；
2、公司代码库重写
 1）老版代码库为PHP，将老版全部页面一页页修改为React页面（通过模块化设计，在不影响体验的情况下一步步移到React），大大提供网站访问速度；
 2）采用多国语言，按需下载JS build；
`),
      image: ImageStarofservice, 
    },
    {
      title: 'My Personal Website',
      year: '2018',
      stack: 'React, Gatsby, Offline',
      description: md(`
**项目简介**: guten.me是一个我自己的个人网站.

**项目特点**:
- 使用React, Gatsby生成静态网站, 访问速度最快并且方便部署到CDN.
- 实现了https, 使用了Let’s Encrypt的免费证书
- 实现了offline模式, 加入了Google Analytics.
      `),
      image: ImageGutenDotMe,
    },
    {
      title: 'It is My Quotes',
      stack: 'React, GraphQL, Responsive',
      year: '2017',
      description: md(`
**项目简介**：展示和搜索名人名言的页面，Quotes可被引用分享至Facebook、Twitter，借此引流量，通过流量变现。

**工作职责**：
该项目为我的独立项目，由我一人进行开发：从设计→开发，前后端部署，搭载后台管理系统，用时2星期完成，Docker、CI自动部署。
      `),
      image: ImageItismyquoteDotCom,
    }, 
    {
      title: '全栈工程师',
      company: 'Poppup, 美国',
      year: '02/2014 - 01/2017',
      stack: 'Node, React, PostgreSQL',
      description: md(` 
**个人背景**: 到2012, Ember.js的出现, 标志着网页开发朝着前段开发为主, 我开始学习Ember, 再到后来的React. 正式的从Ruby on Rails转到了React + Node.js里面.
**项目简介**: Poppup专注在线教育, 是一个刚起步的startup. 

**工作职责**
招聘面试新开发者.
构建项目框架, 带领新开发者完成各项任务.
      `),
      image: ImagePopup, 
    },
    {
      title: 'Rails开发者',
      company: 'Nytla, 印度',
      year: '09/2011 - 01/2014',
      stack: 'Ruby, Ruby on Rails, jQuery',
      description: md(` 
**个人背景**: 从大学一开始, 我就开始使用ArchLinux, 学习C, Python和Ruby. 在2010年开始使用GitHub, 拥抱了开源.
**项目简介**: Nytla是一家印度的软件外包商.

**工作职责**:
主要负责代码整理, 新功能开发.
      `),
      image: ImageJobboard, 
    },
  ]
}