import React, { styled } from 'vendor'

/*
 * <ContactList
 *   items: [ { icon, link }]
 */
class ContactList extends React.Component {
  render() {
    const { items } = this.props
    return (
      <Root>
        {items.map(({Icon, link}) =>
          <A key={link} href={link} target='_blank'>
            <Icon />
          </A>
        )}
      </Root>
    )
  }
}

const Root = styled.div`
  display: flex;
  justify-content: space-between;
`

const A = styled.a`
  display: inline-block;

  & svg {
    width: 2rem;
    fill: ${p => p.theme.text};
  }
`

export default ContactList