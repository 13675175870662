import React, { s, md } from 'vendor'
import ImageItismyquoteDotCom from './assets/itismyquote.com.png'
import ImageGutenDotMe from './assets/guten.me.png'
import ImageStarofservice from './assets/starofservice.com.png'
import ImagePopup from './assets/popup.png'
import ImageJobboard from './assets/jobboard.png'
import IconEmail from './assets/email.svg'
import IconGithub from './assets/github.svg'
import IconLinkedin from './assets/linkedin.svg'

export default {
  // enableScreenshot: true,

  title: 'Guten - JavaScript and Go',

  email: 'ywzhaifei+gutenme at gmail.com',
  contacts: [
    { Icon: IconEmail, link: 'email: ywzhaifei+gutenme at gmail.com' },
    { Icon: IconGithub, link: 'https://github.com/gutenye' },
    { Icon: IconLinkedin, link: 'https://www.linkedin.com/in/gutenye/' },
  ],

  // availableForFreelancing: 'Available For Freelancing!',
  overview: md(`
Guten is a programmer, hacker and open source enthusiast. He is also a senior Javascript developer with 8 years of experience working on the web, core developer of SortableJS. He masters frameworks like React, React Native, Angular, Polymer, etc
  `),

  advantages: md(`
### Experiences:
- 8 years of remote working & freelancing, web development.
- 6 years or React, Node.js full stack development.
- Master React, Mobx, GraphQL, Webpack, Node.js, PostgreSQL, Docker, Continus Integration.
- Familar with Google Cloud Platform, Amazon AWS.
- Have been developed many solo projects.
  `),

  projects: [
    /*
    {
      title: 'ERP',
      description: 'React, Docker, Continus Integration',
      image: null,
    },
    */
    {
      title: 'Lead React Developer',
      company: 'StarOfService, Franch',
      year: '04/2017 - Present',
      stack: 'React, Redux, CSS Modules',
     description: md(`
**Overview**: Founded in August 2013 and based in Paris, France, StarOfService is a trusted marketplace for people to source, discover, and hire the best local Pros around the world, online or from a mobile device, phone or tablet.

**Work**:
1. Manage the team, we are a remote team build with developers from all over the world.
2. Do code review, daily meeting and weekly meeting.
3. Rewrite old PHP codebase to React incremently.
4. Multiple language and on-demand js resource download.
`),
      image: ImageStarofservice, 
    },
    {
      title: 'My Personal Website',
      year: '2018',
      stack: 'React, Gatsby, Offline',
      description: md(`
**Overview**: guten.me is my personal website.

**Features**:
- Generate static site with Gatsby, easy to deploy to CDN.
- Implement https with Let's Encrypt.
- Implemnt offline, Google Analytics.
      `),
      image: ImageGutenDotMe,
    },
    {
      title: 'It is My Quotes',
      stack: 'React, GraphQL, Responsive',
      year: '2017',
      description: md(`
**Overview**: A place for display and sharing quotes.

**Work**:
A standlone project, start from strach and finish it in 2 weeks by myself. Use GraphQL, Node.js as backend, and React as frontend.
      `),
      image: ImageItismyquoteDotCom,
    }, 
    {
      title: 'Full-stack developer',
      company: 'Poppup, United States',
      year: '02/2014 - 01/2017',
      stack: 'Node, React, PostgreSQL',
      description: md(` 
**Background**: With the rise of Ember.js - client side development - in 2012, I started learning it, and then React.js'
**Overview**: Poppup focus on education platform.

**Work**:
1. Poppup is a new startup, so I need to recruit new developers.
2. Design the architecture, and help team mates finish the job.
      `),
      image: ImagePopup, 
    },
    {
      title: 'Rails Developer',
      company: 'Nytla, India',
      year: '09/2011 - 01/2014',
      stack: 'Ruby, Ruby on Rails, jQuery',
      description: md(` 
**Background**: I begin to install and use ArchLinux since grade one. I love open source and be an early GitHub user.

**Work**:
Improve the codebase and site performance.
      `),
      image: ImageJobboard, 
    },
  ]
}