import React from 'react'
import PropTypes from 'prop-types'

export default function withData(WrappedComponent) {
  return class extends React.Component {
    static contextTypes = {
      d: PropTypes.object
    }
    render() {
      return <WrappedComponent {...this.props} {...this.context} />
    }
  }
}